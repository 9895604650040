import {Component, OnInit} from '@angular/core';
import {AppContext} from "../../../app-context";
import {
  IE3ContainerProcessSummary,
  IE3ContainersResult,
  IE3FindContainers,
  IE3RefuseDischargeOverlanded,
  IE3TransportEquipment
} from "@portbase/bezoekschip-service-typescriptmodels";
import {Observable} from "rxjs";
import {openConfirmationModalWithCallback, openEditModal, sendCommand, sendQuery} from "../../../common/utils";
import {map} from "rxjs/operators";
import {WebsocketService} from "../../../common/websocket.service";
import {SentenceCasePipe} from "../../../common/sentence-case.pipe";
import {Router} from "@angular/router";
import lodash from "lodash";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../../../common/modal/modal-confirm.component";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../../consignments/details/master-consignment-details/master-consignment-details.component";
import {ModalErrorAutofocus, ModalErrorAutofocusData} from "../../../common/modal-error/modal-error.component";
import {FacetUtils} from "../../common/facets/facet-utils";
import {FacetedOverview} from "../../common/overview/faceted-overview";
import {FacetedOverviewFilters} from "../../common/overview/overview.component";
import {QuickView} from "../../common/facets/facet-filter/facet-filter.component";

const quickViews: QuickView[] = [{
  code: "ALL_EQUIPMENT",
  name: "All equipment",
  icon: "fa-calendar-days",
  facets: {}
}, {
  code: "ACTION_REQUIRED",
  name: "Action required",
  icon: "fa-circle-exclamation",
  facets: {
    actionRequired: ["true"]
  }
}];

@Component({
  selector: 'app-containers-overview',
  templateUrl: './containers-overview.component.html',
  styleUrls: ['./containers-overview.component.scss'],
  providers: [WebsocketService]
})
export class ContainersOverviewComponent extends FacetedOverview<IE3ContainerProcessSummary, ContainerOverviewFilters> implements OnInit {
  appContext = AppContext;
  data: IE3ContainerProcessSummary[] = [];

  constructor(private websocket: WebsocketService<IE3ContainerProcessSummary>, private router: Router) {
    super("container-overview-filters", quickViews, quickViews.find(q => q.code === "ACTION_REQUIRED"));
    if (AppContext.isProduction() && !AppContext.isAdmin()) {
      router.navigateByUrl("/");
    }
  }

  getData = (term: string): Observable<IE3ContainersResult> => {
    if (term) {
      this.data = [];
    }
    if (!this.filters.overviewFilters) {
      this.filters.overviewFilters = FacetUtils.defaultOverviewFilters();
    }
    this.loading = true;
    this.saveFiltersInLocalStorage(this.filters);
    return sendQuery("com.portbase.bezoekschip.common.api.consignments.containers.queries.FindContainers", <IE3FindContainers>{
      term: term,
      from: this.from,
      maxHits: this.maxItems,
      facetFilters: this.getFacetFilters()
    }, {showSpinner: true}).pipe(map((result: IE3ContainersResult) => {
      this.loading = false;
      return result;
    }));
  };

  ngOnInit(): void {
    this.websocket.initialise(`/api/ui/containers`,
      (update: IE3ContainerProcessSummary) => this.updateFromWebsocket(update), true);
  }

  renderRecords = (result: IE3ContainersResult, append: boolean) => {
    this.saveFiltersInLocalStorage(this.filters);
    if (result.containers.length < this.maxItems) {
      this.endReached = true;
    }
    this.data = append ? (this.data || []).concat(result.containers) : result.containers;
    this.setFacetsFromResult(result.facets);
  }

  trackByRecord = (index: number, record: IE3ContainerProcessSummary): any => record.containerNumber;

  private updateFromWebsocket(update: IE3ContainerProcessSummary) {
    const existingContainer = this.data.find(
      c => c.containerProcessId === update.containerProcessId);
    if (existingContainer) {
      this.data[this.data.indexOf(existingContainer)] = update;
    }
  }

  nameFormatter = (name: string): string => {
    switch (name) {
      case "consignmentDataSummaries/consignmentNumber": return "B/L number";
      case "terminalDataSummary/terminal": return "Terminal of discharge";
      default: return SentenceCasePipe.formatWithSpaces(name);
    }
  }

  getValueFormatter = (name: string): (value: string) => string => {
    switch (name) {
      default:
        return SentenceCasePipe.format;
    }
  }

  getHiddenFacets = (): string[] => {
    const hiddenFields: string[] = ["count"];
    if (!AppContext.isAdmin()) {
      return hiddenFields.concat(["iamConnectedId"]);
    }
    return hiddenFields;
  }

  get containerCount(): number {
    return lodash.sum(this.allFacets?.find(f => f.name === "count")?.values
      .map(v => v.count)) || 0
  }

  getSelectedContainers(): IE3ContainerProcessSummary[] {
    return this.data.filter(c => c["selected"] == true);
  }

  get nrOfSelectedContainers(): number {
    return this.getSelectedContainers().length;
  }

  get containersHaveBeenSelected(): boolean {
    return this.getSelectedContainers().length > 0;
  }

  get selectedOverlandedContainers(): IE3ContainerProcessSummary[] {
    return this.getSelectedContainers().filter(s => s.overlanded);
  }

  refuseOverlandedContainers = (selectedContainers: IE3ContainerProcessSummary[]) => {
    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        this.doRefuseOverlandedContainers(selectedContainers);
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      type: "danger",
      title: "Refuse containers",
      message: "You are about to refuse " + selectedContainers.length + " overlanded containers",
      question: "Are you sure that you want to refuse these overlanded containers?",
      confirmText: "Yes",
      cancelText: "No"
    }, 'static');
  };

  private doRefuseOverlandedContainers(selectedContainers: IE3ContainerProcessSummary[]) {
    selectedContainers.forEach(containerProcess => {
      sendCommand('com.portbase.bezoekschip.common.api.consignments.containers.commands.RefuseDischargeOverlanded',
        <IE3RefuseDischargeOverlanded>{
          containerNumber: containerProcess.containerNumber,
          crn: containerProcess.crn
        },
        () => AppContext.registerSuccess(this.getSelectedContainers.length + ' overlanded containers have been refused successfully.')
      );
    });
  }

  createConsignment = (selectedContainers: IE3ContainerProcessSummary[]) => {
    var crnList = selectedContainers
      .map(c => c.crn)
      .reduce((acc, current) => {
        if (!acc.includes(current)) {
          acc.push(current);
        }
        return acc;
      }, [] as string[]);
    if (crnList.length > 1) {
      this.showError();
    } else {
      openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
        crn: crnList[0],
        consignmentProcessId: null,
        filingType: null,
        transportEquipmentMap: this.summaryToTransportEquipment(selectedContainers),
        cachedConsignmentProcess: null
      })
    }
  };

  private summaryToTransportEquipment(selectedContainers: IE3ContainerProcessSummary[]):
    { [containerIdentificationNumber: string]: IE3TransportEquipment } {
    const transportEquipment = {};

    selectedContainers.forEach(s => {
      transportEquipment[s.containerNumber] = <IE3TransportEquipment>{
        containerIdentificationNumber: s.containerNumber,
        containerOperator: s.terminalDataSummary?.containerOperator
      };
    })

    return transportEquipment;
  }

  private showError = () => {
    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        // Do nothing
      }
    }, ModalErrorAutofocus, <ModalErrorAutofocusData>{
      type: "warning",
      title: "Not allowed",
      message: "It is not allowed to add containers from more than one visit in one consignment",
    }, 'static');
  }
}

interface ContainerOverviewFilters extends FacetedOverviewFilters<any> {
}
