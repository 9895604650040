import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {NewVisitSummary} from "./visit-overview-item/visit-overview-item.component";
import {sendQuery} from "../../common/utils";
import {
  FacetValueResult,
  FindVisits,
  FindVisitsResult,
  GetVesselImageUrls,
  SortDirection,
  VisitSummary,
  VisitSummarySortingProperty,
  VisitSummaryStatus
} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../../app-context";
import lodash, {cloneDeep} from "lodash";
import {VisitOverviewUtils} from "./visit-overview-item/visit-overview.utils";
import {map} from "rxjs/operators";
import {DateFieldRange, MomentDateFieldRange} from "../../common/date/date-range/date-field-range";
import moment from "moment/moment";
import {ClearanceService} from "../../visit-details/visit/clearance/clearance-service";
import {FacetStats, QuickView} from "../common/facets/facet-filter/facet-filter.component";
import {SentenceCasePipe} from "../../common/sentence-case.pipe";
import {PortvisitUtils} from "../../refdata/portvisit-utils";
import {FacetUtils} from "../common/facets/facet-utils";
import {VisitContext} from "../../visit-details/visit-context";
import {FacetedOverview} from "../common/overview/faceted-overview";
import {FacetedOverviewFilters} from "../common/overview/overview.component";

const quickViews: QuickView[] = [{
  code: "ALL_VISITS",
  name: "All visits",
  icon: "fa-calendar-days",
  facets: {}
}, {
  code: "PLANNED_EXPECTED",
  name: "Planned & expected",
  icon: "fa-calendar-day",
  facets: {
    visitStatus: ["PLANNED", "EXPECTED"]
  }
}, {
  code: "ARRIVED",
  name: "Arrived",
  icon: "fa-calendar-check",
  facets: {
    visitStatus: ["ARRIVED"]
  }
}];

@Component({
  selector: 'app-visit-overview',
  templateUrl: './visit-overview.component.html',
  styleUrls: ['./visit-overview.component.scss']
})
export class VisitOverviewComponent extends FacetedOverview<NewVisitSummary, VisitOverviewFilters> implements OnInit {
  appContext = AppContext;
  sortingProperties: VisitSummarySortingProperty[] = [VisitSummarySortingProperty.CREATED, VisitSummarySortingProperty.ARRIVAL_DATE];
  cachedVisits: { [key: string]: VisitSummary[] } = {};
  hiddenFacets: string[] = ["count", "ownerShortName", "declarantShortName", "allowedViewers"]
    .concat(VisitContext.showTerminalStatus() ? [] : ["rtaUnequalEta"])
    .map(h => h.toLowerCase());
  facetOrdering: string[] = ["visitStatus", "portName", "stevedore", "quay", "tidalShip",
    "unreadHarbourRemarks", "overlandedContainers", "shortlandedContainers"];
  otherFacetNames: string[] = ["overlandedContainers", "shortlandedContainers"];
  totalCount: number = 0;

  constructor(private clearanceService: ClearanceService) {
    super("visit-overview-filters", quickViews, quickViews.find(q => q.code === "ALL_VISITS"));
  }

  getData = (term: string): Observable<FindVisitsResult> => {
    this.loading = true;
    const timeRange = cloneDeep(this.filters.overviewFilters.filters.timeRange);
    if (timeRange) {
      timeRange.end = moment(timeRange.end).add(1, 'days').toISOString();
    }
    if (!this.filters.overviewFilters) {
      this.filters.overviewFilters = FacetUtils.defaultOverviewFilters();
    }
    this.saveFiltersInLocalStorage(this.filters);
    return sendQuery("com.portbase.bezoekschip.common.api.visit.FindVisits", <FindVisits>{
      term: term,
      dateTimeRange: timeRange,
      from: this.from,
      maxHits: this.maxItems,
      sortingProperty: this.sortingProperty,
      sortDirection: this.sortDirection,
      ownCallsOnly: this.filters.ownCallsOnly,
      facetFilters: this.getFacetFilters()
    }, {showSpinner: true, caching: false}).pipe(map(result => {
      this.loading = false;
      this.loadCount++;
      return result;
    }));
  };

  ngOnInit(): void {
    if (this.feedbackEnabled()) {
      this.showFeedbackButtonBasedOnLanguage();
    }
  }

  get sortingProperty(): VisitSummarySortingProperty {
    return this.filters?.sortingProperty || VisitSummarySortingProperty.ARRIVAL_DATE;
  }

  get sortDirection(): SortDirection {
    return this.getSortDirection(this.sortingProperty);
  }

  get visitStatusFilterIncludingFacets(): VisitSummaryStatus[] {
    return this.getMergedFacetFilters()["visitStatus"]?.map(v => v as VisitSummaryStatus) || []
  }

  getHiddenFacets = (): string[] => [];

  changeSorting = (sortingProperty: VisitSummarySortingProperty) => {
    this.filters.sortingProperty = sortingProperty;
    this.saveFiltersInLocalStorage(this.filters);
    this.endReached = false;
    this.from = 0;
    this.loadData();
  }

  setOwnsCallsOnly = (ownCallsOnly: boolean) => {
    this.filters.ownCallsOnly = ownCallsOnly;
    this.saveFiltersInLocalStorage(this.filters);
    this.endReached = false;
    this.from = 0;
    this.loadData();
  }

  arrivalDateChanged = (date: DateFieldRange) => {
    this.filters.overviewFilters.filters.timeRange = date ? <DateFieldRange>{
      start: moment(date.start).toISOString(),
      end: moment(date.end).toISOString()
    } : null;
    this.from = 0;
    this.endReached = false;
    this.data = [];
    this.loadData();
  }

  private updateVesselImages(result: FindVisitsResult) {
    return sendQuery("com.portbase.bezoekschip.common.api.visit.GetVesselImageUrls", <GetVesselImageUrls>{
      imoCodes: result.visits.map(visit => visit.imoCode)
    }, {showSpinner: false, caching: false}).subscribe(vesselImages => {
        this.data.forEach(visit => {
          if (vesselImages.hasOwnProperty(visit.imoCode)) {
            visit.vesselImageUrl = vesselImages[visit.imoCode]
          }
        })
      }
    );
  }

  renderRecords = (result: FindVisitsResult, append: boolean) => {
    this.saveFiltersInLocalStorage(this.filters);
    if (!append) {
      this.cachedVisits = {};
    }
    const visits = result.visits.map(v => VisitOverviewUtils.toExtendedSummary(v, this.clearanceService));
    Object.entries(lodash.groupBy(visits, (v) => v.visitStatus))
      .forEach(e => this.cachedVisits[e[0]] = (this.cachedVisits[e[0]] || []).concat(e[1]))
    if (visits.length < this.maxItems) {
      this.endReached = true;
    }
    this.data = append ? (this.data || []).concat(visits) : visits;
    this.setFacetsFromResult(result.facets);
    const countFacet = Object.entries(result.facets)
      .find(e => e[0].toLowerCase() === "count");
    this.totalCount = countFacet ? lodash.sum(countFacet[1].map(e => e.count)) : 0;
    this.updateVesselImages(result)
  }

  trackByRecord = (index: number, obj: NewVisitSummary): any => obj.crn;

  nameFormatter = (name: string): string => {
    switch (name) {
      case "unreadHarbourRemarks": return "Harbour master remarks";
      case "rtaUnequalEta": return "Terminal: date/time mismatch";
      case "bunkeringLate": return "Bunkering may/will cause delay";
      default: return SentenceCasePipe.formatWithSpaces(name);
    }
  }

  private getSortDirection(property: VisitSummarySortingProperty): SortDirection {
    const visitSummaryStatuses = this.visitStatusFilterIncludingFacets;
    return property == VisitSummarySortingProperty.ARRIVAL_DATE
      ? visitSummaryStatuses.length == 0 || visitSummaryStatuses.includes(VisitSummaryStatus.CANCELLED) || visitSummaryStatuses.includes(VisitSummaryStatus.DEPARTED)
        ? SortDirection.DESC : SortDirection.ASC
      : SortDirection.DESC;
  }

  getSortDirectionFormatter(sortDirection: SortDirection): string {
    switch (sortDirection) {
      case SortDirection.ASC: return "ascending";
      case SortDirection.DESC: return "descending";
    }
  }

  static visitStatusFormatter: (value: string) => string = v => {
    if (v === "ARRIVED") return "In Port"
    return PortvisitUtils.enumFormatter(v);
  };

  getValueFormatter = (name: string): (value: string) => string => {
    switch (name) {
      case "visitStatus": return VisitOverviewComponent.visitStatusFormatter;
      case "quay": return SentenceCasePipe.format;
      case "orderStatus": return PortvisitUtils.enumFormatter;
      default: return null;
    }
  }

  getValuesSelectedCallback = (name: string): (facet: FacetStats, selectedValues: string[]) => string[] => {
    switch (name) {
      case "overlandedContainers": case "shortlandedContainers":
        return (facet, selectedValues) => selectedValues.includes("true")
          ? AppContext.isAdmin() ? ["ADMIN"] : [AppContext.userProfile.organisation.shortName]
          : [];
      default: return null;
    }
  }

  protected sortingFormatter = (sortingProperty: VisitSummarySortingProperty): string => {
    switch (sortingProperty) {
      case VisitSummarySortingProperty.ARRIVAL_DATE: return `Arrival (${this.getSortDirectionFormatter(this.getSortDirection(sortingProperty))})`;
      case VisitSummarySortingProperty.CREATED: return `Created (${this.getSortDirectionFormatter(this.getSortDirection(sortingProperty))})`;
    }
  }

  filterFacetValues = (name: string, values: FacetValueResult[]): FacetValueResult[] => {
    switch (name) {
      case "overlandedContainers": case "shortlandedContainers":
        const facetValues = values.find(v => AppContext.isAdmin() ? v.value === "ADMIN"
          :  v.value === AppContext.userProfile.organisation.shortName);
        return [{
          value: "true",
          count: facetValues?.count || 0
        }];
      default:
        return values;
    }
  }

  ownCallsFormatter = (ownCallsOnly: boolean) => ownCallsOnly ? "My calls" : "All calls";

  showFeedbackButtonBasedOnLanguage() {
    var userLang = navigator.language || navigator["userLanguage"];
    var feedbackButton = document.getElementById('feedbackButton');
    if (userLang.startsWith("nl")) {
      // Stelt de link en tekst in voor Nederlands sprekende browsers
      feedbackButton["href"] = "https://forms.office.com/e/m36kxNysYv";
      feedbackButton.textContent = 'Geef feedback';
      feedbackButton.style.display = 'block';
    } else {
      // Stelt een andere link en tekst in voor niet-Nederlands sprekende browsers
      feedbackButton["href"] = "https://forms.office.com/e/mDRwUyxE3C";
      feedbackButton.textContent = 'Give feedback';
      feedbackButton.style.display = 'block';
    }
  }

  ranges: MomentDateFieldRange[] = [
    {
      label: 'Today',
      start: moment().startOf('day'),
      end: moment().add(1, "day").startOf('day').subtract(1, 'day')
    },
    {
      label: 'Yesterday',
      start: moment().subtract(1, 'day').startOf('day'),
      end: moment().startOf('day').subtract(1, 'day')
    },
    {
      label: 'This week',
      start: moment().startOf('week'),
      end: moment().add(1, "week").startOf('week').subtract(1, 'day')
    },
    {
      label: 'Last 7 days',
      start: moment().subtract(7, 'day').startOf('day'),
      end: moment().add(1, "day").startOf('day').subtract(1, 'day')
    },
    {
      label: 'Next 7 days',
      start: moment().startOf('day'),
      end: moment().add(7, "day").startOf('day').subtract(1, 'day')
    }
  ];

  feedbackEnabled() {
    return false;
  }
}

interface VisitOverviewFilters extends FacetedOverviewFilters<VisitOverviewFacetFilters> {
  sortingProperty?: VisitSummarySortingProperty;
  sortDirection?: SortDirection;
  ownCallsOnly?: boolean;
}

interface VisitOverviewFacetFilters {
  timeRange?: DateFieldRange;
}
