<app-form-group class="{{info.classes}}" [ngClass]="{'disabled': info.disabled}" [omitVerticalPadding]="!!info.subLabel">
  <label for="{{info.id}}"
         style="display: grid; grid-column-gap: 1ex; {{info.columns}}">
    <span class="bullet-with-text"
          style="font-size: smaller;
                 color: #ec6726; background-color: white; border: 1px solid #ec6726;
                 grid-row-start: 1; grid-row-end: 3; ">
      {{ index }}
    </span>
    <div>
      {{ info.label }}
      <span [ngClass]="{'required': info.required}"></span>
      <app-info *ngIf="info.info">
        <span class="tooltipContent">{{ info.info }}</span>
      </app-info>
    </div>
    <div *ngIf="info.subLabel" class="ellipsis" style="font-size: smaller;">{{ info.subLabel }}</div>
  </label>
  <div id="{{info.id}}">
    <ng-container #holder></ng-container>
  </div>
</app-form-group>
