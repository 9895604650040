import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentRef,
  createComponent,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {DateTimeEntry} from "./berth-visit-details.component";

@Component({
  selector: 'app-labeled-date-time',
  templateUrl: './labeled-date-time.component.html',
  styleUrls: ['./labeled-date-time.component.css']
})
export class LabeledDateTimeComponent implements AfterViewInit {
  private _info: DateTimeEntry;
  private _index: number;

  @ViewChild("holder", {read: ViewContainerRef, static: true}) holder: ViewContainerRef;

  constructor(private appRef: ApplicationRef) {
  }

  // Our parent is created in ngOnInit, so we must be later.
  ngAfterViewInit(): void {
      if (this.info.dateTimeType) {
        // Create component and replace the child(ren) of the hostElement
        const host: Element = document.getElementById(this.info.id);
        const dateField: ComponentRef<any> = createComponent(this.info.dateTimeType, {
          hostElement: host,
          environmentInjector: this.appRef.injector
        })
        this.appRef.attachView(dateField.hostView);
        if (this.info.init) {
          this.info.init(dateField);
        } else {
          dateField.instance.disabled = this.info.disabled;
          dateField.instance.writeValue(this.info.time);
          dateField.instance.registerOnChange(this.info.onChanged);
        }
      } else {
        this.holder.createEmbeddedView(this.info.dateTimeTemplate!);
      }
  }

  set info(value : DateTimeEntry) {
    this._info = value;
  };

  get info(): DateTimeEntry {
    return this._info;
  }

  set index(value: number) {
    this._index = value;
  }

  get index() {
    return this._index;
  }
}
