import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../../app-context";
import lodash from "lodash";
import {openEditModal} from "../../../../../common/utils";
import {
  EquipmentConsignmentDetailsComponent,
  EquipmentConsignmentDetailsComponentData
} from "../../../details/equipment-consignment-details/equipment-consignment-details.component";
import {ConsignmentUtils, EquipmentSummaryItem} from "../../../consignment.utils";

@Component({
  selector: 'app-consignment-equipment-overview-item',
  templateUrl: './consignment-equipment-overview-item.component.html',
  styleUrls: ['./consignment-equipment-overview-item.component.scss', '../master-consignment-overview-item.component.scss']
})
export class ConsignmentEquipmentOverviewItemComponent {
  utils = ConsignmentUtils;
  appContext = AppContext;

  @Input() consignmentProcessId: string;
  @Input() equipment: EquipmentSummaryItem;
  @Input() term: string;
  @Input() depth: number;
  @Input() selectable: boolean;
  @Input() collapsible: boolean;

  paddingPerStep: number = 20;

  collapsed: boolean;

  get indentionDepth() {
    return this.depth * this.paddingPerStep;
  }

  get goods() {
    return this.equipment.goodsItems.map(g => g.goodsItem);
  }

  get totalWeight() {
    return lodash.round(lodash.sum(this.equipment.goodsItems.map(g => g.placement.grossWeight)), 1);
  }

  get totalPackages() {
    return lodash.sum(this.equipment.goodsItems.map(g => g.placement.numberOfPackages));
  }

  get containerType() {
    return this.equipment.equipment ? this.equipment.equipment.empty ? "Empty container" : "Container" : "Bulk";
  }

  editEquipment = () => openEditModal(EquipmentConsignmentDetailsComponent, <EquipmentConsignmentDetailsComponentData>{
    consignmentProcessId: this.consignmentProcessId,
    equipmentNumber: this.equipment.equipment.equipmentNumber
  }, {
    warnOnClose: true,
    currentToStack: true
  });
}
