import {Component, Input} from '@angular/core';
import {BerthVisit, TerminalVisit} from "@portbase/bezoekschip-service-typescriptmodels";
import {VisitContext} from "../../../../../visit-context";
import {AppContext} from "../../../../../../app-context";
import {PortvisitUtils} from "../../../../../../refdata/portvisit-utils";

@Component({
  selector: 'app-terminal-status-admin',
  templateUrl: './terminal-status-admin.component.html',
  styleUrls: ['./terminal-status-admin.component.css']
})
export class TerminalStatusAdminComponent {
  appContext = AppContext;
  utils = PortvisitUtils;

  @Input() berthVisit: BerthVisit;
  @Input() terminalVisit: TerminalVisit;

  openAcceptStatusModal() {
    $('#' + this.berthVisit.id + '-acceptStatusModal').modal("show");
  }

  showManuallyRegisterTerminalStatus() {
    return VisitContext.showTerminalStatus() &&  AppContext.environment === "localhost" ||  AppContext.environment === "kt";
  }
}
