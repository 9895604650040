<div class="position-fixed sticky-top w-100" style="left: 0; right: 0; z-index: 15000;">
  <app-alerting></app-alerting>
</div>
<app-overview [term]="filters.overviewFilters.term" [overviewFilters]="filters.overviewFilters"
              [searchFunction]="searchTermFunction" (resultsFound)="renderRecords($event, false)"
              [isEmpty]="isEmpty" [facetNameFormatter]="nameFormatter" [facets]="facets" [otherFacets]="otherFacets"
              (filtersCleared)="clearFilters($event)" (facetFiltersChanged)="facetFiltersChanged($event)"
              (loadNextPage)="loadNextPage()" [quickViews]="quickViews">
  <div class="header-bar d-flex">
    <button *ngIf="appContext.isCargoDeclarant()" class="btn btn-primary text-decoration-none"
            (click)="createConsignment()">
      <i class="button-icon fa-light fa-plus"></i>
      <span class="ps-2 d-none d-md-inline">New Master B/L</span>
    </button>
    <div class="sub-text ms-3 align-items-center d-flex">
      <i class="fa fa-fw fa-light fa-xl fa-file-lines me-1"></i>
      <span>{{masterConsignmentCount}}</span>
    </div>
    <div class="sub-text ms-3 align-items-center d-flex">
      <i class="fa fa-kit fa-fw fa-light fa-xl fa-pb-file-line me-1"></i>
      <span>{{houseConsignmentCount}}</span>
    </div>
    <div class="sub-text ms-3 align-items-center d-flex">
      <i class="fa fa-fw fa-light fa-xl fa-container-storage me-1"></i>
      <span>{{equipmentCount}}</span>
    </div>
  </div>
  <div class="overview-items">
    <app-master-consignment-overview-item *ngFor="let consignment of data; trackBy: trackByRecord"
                                          [consignment]="consignment" [term]="filters.overviewFilters.term"
                                          [depth]="0" [selectable]="true" [collapsible]="!!consignment.masterConsignment.equipments?.length"></app-master-consignment-overview-item>
  </div>
  <div class="empty-container col-12 h-100 d-flex align-items-center justify-content-center flex-column">
    <span class="h1 mb-2">No consignments</span>
    <span class="text-secondary">Try adjusting your filters or search term</span>
  </div>
</app-overview>
