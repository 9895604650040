import {Component, Input, ViewChild} from '@angular/core';
import {
  DeclarationType,
  OrderStatus,
  TaskMessageStatus,
  VisitSummaryStatus
} from "@portbase/bezoekschip-service-typescriptmodels";
import {NewVisitSummary} from "../visit-overview-item.component";
import {DeclarationMessageStatus, VisitOverviewUtils} from "../visit-overview.utils";
import {TooltipComponent} from "../../../../common/tooltip/tooltip.component";
import {ClearanceService} from "../../../../visit-details/visit/clearance/clearance-service";
import {AppContext} from "../../../../app-context";
import {lodash} from "../../../../common/utils";

@Component({
  selector: 'app-visit-overview-service-status',
  templateUrl: './visit-overview-service-status.component.html',
  styleUrls: ['./visit-overview-service-status.component.scss']
})
export class VisitOverviewServiceStatusComponent {
  _summary: NewVisitSummary;
  _declarationTypes: DeclarationType[];
  messageStatus: TaskMessageStatus;
  messageStatuses: DeclarationMessageStatus[] = [];
  _orderStatus: boolean;
  _inspection: boolean;

  @ViewChild("tooltipComponent") tooltipComponent: TooltipComponent;

  constructor(protected clearanceService: ClearanceService) {
  }

  @Input() set orderStatus(orderStatus: boolean) {
    this._orderStatus = orderStatus;
    this.updateMessageStatus();
  }

  @Input() set inspectionStatus(inspection: boolean) {
    this._inspection = inspection;
    this.updateMessageStatus();
  }

  @Input()
  set summary(summary: NewVisitSummary) {
    this._summary = summary;
    this.updateMessageStatus();
  }

  @Input()
  set declarationTypes(declarationTypes: DeclarationType[]) {
    this._declarationTypes = declarationTypes;
    this.updateMessageStatus();
  }

  protected updateMessageStatus() {
    if (this._orderStatus) {
      this.setBasedOnOrderStatus();
      let bunkeringStatus = this._summary.taskStatuses[DeclarationType.BUNKERING];
      if (bunkeringStatus && bunkeringStatus.status != TaskMessageStatus.UNKNOWN) {
        let bunkeringTaskStatus = VisitOverviewUtils.getBunkeringTaskStatuses(this._summary, this.clearanceService);
        this.messageStatuses = this.messageStatuses.concat(bunkeringTaskStatus);
        this.messageStatus = VisitOverviewUtils.reduceLeastTaskMessageStatus([bunkeringTaskStatus[0].taskStatus, this.messageStatus])
      }
    } else {
      this.messageStatuses = VisitOverviewUtils.getTaskStatuses(this._summary, this._declarationTypes, this.clearanceService);
      this.messageStatus = VisitOverviewUtils.getLeastTaskStatusByDeclarations(this._summary, this._declarationTypes, this.clearanceService);
    }
    if (this._inspection) {
      this.messageStatuses.push(this.getInspectionStatus());
      this.messageStatus = VisitOverviewUtils.getLeastTaskMessageStatus(this.messageStatuses);
    }
  }

  protected setBasedOnOrderStatus = () => {
    this.messageStatus = this.orderStatusToTaskStatus(this._summary.orderStatus) ||
      (this._summary.visitStatus === VisitSummaryStatus.DEPARTED ? TaskMessageStatus.ACCEPTED : TaskMessageStatus.UNKNOWN);
    const message = this.orderStatusMessage(this._summary.orderStatus);
    this.messageStatuses = [{
      name: "Pilot, tugboat and boatmen",
      taskStatus: this.messageStatus,
      messages: message ? [message] : []
    }];
  }

  private orderStatusToTaskStatus = (orderStatus: OrderStatus): TaskMessageStatus => {
    switch (orderStatus) {
      case OrderStatus.CANCELLED: return TaskMessageStatus.REJECTED;
      case OrderStatus.CONFIRMED: return TaskMessageStatus.ACCEPTED;
      case OrderStatus.CONFIRMED_NEW_PROPOSAL: return TaskMessageStatus.WARNING;
      case OrderStatus.ETA_CHANGED: return TaskMessageStatus.ACCEPTED_WITH_CONDITIONS;
      case OrderStatus.ETD_CHANGED: return TaskMessageStatus.ACCEPTED_WITH_CONDITIONS;
      case OrderStatus.ORDERED: return TaskMessageStatus.PENDING;
      case OrderStatus.REEVALUATED: return TaskMessageStatus.PENDING;
      case OrderStatus.REJECTED: return TaskMessageStatus.REJECTED;
    }
  }

  private orderStatusMessage = (orderStatus: OrderStatus): string => {
    switch (orderStatus) {
      case OrderStatus.REJECTED: case OrderStatus.CANCELLED: return this._summary.orderRejectReason;
      case OrderStatus.CONFIRMED_NEW_PROPOSAL: return null;
      case OrderStatus.ETA_CHANGED: return "ETA has changed";
      case OrderStatus.ETD_CHANGED: return "ETD has changed";
      default: return null;
    }
  }

  private getInspectionStatus = (): DeclarationMessageStatus => {
    let inspectionsStarted = false;
    let inspectionsCompleted = false;
    if (AppContext.isAdmin() || AppContext.isInspectionHandler()) {
      const statuses = lodash.values(this._summary.cargoAgentStatuses);
      inspectionsStarted = statuses.some(s => s.inspectionsStarted);
      inspectionsCompleted = inspectionsStarted && statuses.filter(s => s.inspectionsStarted).every(s => s.inspectionsCompleted);
    } else if (AppContext.isInspectionViewer()) {
      const status = this._summary.cargoAgentStatuses[AppContext.userProfile.organisation?.shortName];
      inspectionsStarted = status && status.inspectionsStarted;
      inspectionsCompleted = status && status.inspectionsCompleted;
    }
    if (inspectionsCompleted) {
      return {
        taskStatus: TaskMessageStatus.ACCEPTED,
        name: "Inspection",
        messages: []
      }
    } else if (inspectionsStarted) {
      return {
        taskStatus: TaskMessageStatus.WARNING,
        name: "Inspection",
        customLabel: "Inspection reported",
        messages: []
      }
    }
    return {
      taskStatus: TaskMessageStatus.DISABLED,
      name: "Inspection",
      messages: []
    }
  }
}
