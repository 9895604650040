import {Component, Input} from '@angular/core';
import {VisitSummaryExpanded} from "../../../visit-overview/visit-overview.component";
import moment from "moment/moment";
import {
  BerthVisitSearch,
  CancelVisit,
  IsAllowedToCancelVisit,
  LastEditedBy,
  VisitStatus,
  VisitSummary,
  VisitSummaryStatus
} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../../../app-context";
import {VisitOverviewUtils} from "./visit-overview.utils";
import {openConfirmationModalWithCallback, sendCommand, sendQuery} from "../../../common/utils";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../../../common/modal/modal-confirm.component";
import {ClearanceService} from "../../../visit-details/visit/clearance/clearance-service";
import {PortvisitUtils} from "../../../refdata/portvisit-utils";

@Component({
  selector: 'app-visit-overview-item',
  templateUrl: './visit-overview-item.component.html',
  styleUrls: ['./visit-overview-item.component.scss']
})
export class VisitOverviewItemComponent {
  utils = VisitOverviewUtils;
  appContext = AppContext;
  portVisitUtils = PortvisitUtils;
  @Input() summary: NewVisitSummary;
  @Input() term: string;

  constructor(protected clearanceService: ClearanceService) {
  }

  formatDate = (dateString: string): string => !!dateString ? moment(dateString).format("DD MMM HH:mm") : "No date";

  getNextDate = (berthVisit: BerthVisitSearch): string => berthVisit.ata || berthVisit.atd || berthVisit.eta || berthVisit.etd;

  hasNextDate = (berthVisit: BerthVisitSearch): boolean => !!this.getNextDate(berthVisit);

  getBerthVisitName = (berthVisit: BerthVisitSearch) => (berthVisit.name || berthVisit.terminalName || berthVisit.stevedore) || 'No berth(s)';

  getBerthVisitStatus = (berthVisit: BerthVisitSearch): VisitStatus => berthVisit.atd ? VisitStatus.DEPARTED : berthVisit.ata ? VisitStatus.ARRIVED : VisitStatus.EXPECTED

  get entryDate() {
    return this.summary.ataPort || this.summary.etaPort;
  }

  get exitDate() {
    return this.summary.atdPort || this.summary.etdPort;
  }

  get entryPointName() {
    return this.summary.entryPoint?.name || 'No entry point';
  }

  get exitPointName() {
    return this.summary.exitPoint?.name || 'No exit point';
  }

  get expected() {
    return [VisitSummaryStatus.DRAFT, VisitSummaryStatus.PLANNED, VisitSummaryStatus.EXPECTED].includes(this.summary.visitStatus);
  }

  isOrganisationCurrentVisitDeclarant = (): boolean => {
    return AppContext.isAdmin()
      || this.summary.declarantShortName === AppContext.userProfile.organisation?.shortName
      || this.summary.ownerShortName === AppContext.userProfile.organisation?.shortName;
  }

  get navigationUrl(): string {
    if (this.summary.oldVisit) {
      const environmentMatch = window.location.href.match(/https:\/\/portvisit\.(.*)\.portbase\.com.*/);
      if (environmentMatch && environmentMatch[1] === 'pcs') {
        return 'https://vesselcall.' + AppContext.environment +
            '.portbase.com/beta/index.html#!/details/' + this.summary.crn + '/portauthorityDetails';
      } else {
        return 'https://vesselcall.' + AppContext.environment +
            '.portbase.com/#!/details/' + this.summary.crn + '/portauthorityDetails';
      }
    } else {
      return '/details/' + this.summary.crn;
    }
  }

  tryRefreshVisitSummary = () => {
    sendCommand('com.portbase.bezoekschip.common.api.visit.RefreshVisitSummary', {
      crn: this.summary.crn
    }, (summary: VisitSummary) => {
      this.summary = VisitOverviewUtils.toExtendedSummary(summary, this.clearanceService);
      AppContext.registerSuccess('Visit summary has been rebuild successfully.');
    });
  }

  cancelVisit = () => {
    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        this.doCancelVisit();
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      type: "danger",
      title: "Danger",
      message: "Once a visit is cancelled it is no longer possible to make changes.",
      question: "Are you sure that you want to cancel this visit?",
      confirmText: "Yes",
      cancelText: "No"
    }, 'static');
  }

  doCancelVisit = () => {
    sendQuery('com.portbase.bezoekschip.common.api.visit.IsAllowedToCancelVisit', <IsAllowedToCancelVisit>{
      crn: this.summary.crn
    }, {caching: false, showSpinner: true}).subscribe(mayBeCancelled => {
      if (mayBeCancelled) {
        sendCommand('com.portbase.bezoekschip.common.api.visit.CancelVisit', <CancelVisit>{
          crn: this.summary.crn
        }, () => {
          this.summary.cancelled = true;
          this.summary.visitStatus = VisitSummaryStatus.CANCELLED;
          AppContext.registerSuccess('The visit was cancelled successfully.');
        });
      } else {
        AppContext.registerError('Visit cannot be cancelled because cargo is registered and/or reported for its berths or ports');
      }
    });
  }
}

export interface NewVisitSummary extends VisitSummaryExpanded {
  terminalPlanningEnabled?: boolean;
  currentBerthVisit?: BerthVisitSearch;
  lastEditedByUser?: LastEditedBy;
}
