import {Component, Input} from '@angular/core';
import {openEditModal} from "../../../../../common/utils";
import {
  HouseConsignmentDetailsComponent,
  HouseConsignmentDetailsComponentData
} from "../../house-consignment-details/house-consignment-details.component";

@Component({
  selector: 'app-consignment-house-column',
  templateUrl: './consignment-house-column.component.html',
  styleUrls: ['./consignment-house-column.component.scss']
})
export class ConsignmentHouseColumnComponent {
  @Input() consignmentProcessId: string;
  @Input() houseConsignments: string[];
  @Input() term: string;

  editHouseConsignment = (houseConsignmentNumber: string) => openEditModal(HouseConsignmentDetailsComponent, <HouseConsignmentDetailsComponentData>{
    consignmentProcessId: this.consignmentProcessId,
    consignmentNumber: houseConsignmentNumber
  }, {
    warnOnClose: true,
    currentToStack: true
  });
}
