<span class="d-block fw-regular mb-3">{{label}}</span>
<ng-container *ngIf="party || editMode else noParty">
  <div *ngIf="editMode" class="row mb-3">
    <div class="col-6">
      <app-search [required]="required" [(ngModel)]="party" [searchFunction]="utils.findParties" [minCharacters]="0"
                  [resultFormatter]="utils.partyFormatter" [inputFormatter]="utils.partyFormatter"
                  (ngModelChange)="onPartyChange($event)" placement="Select a party from your address book"
                  [newValueFromInputFunction]="newParty">
      </app-search>
    </div>
  </div>
  <div *ngIf="party" class="card form-group">
    <div class="card-body p-4">
      <div class="row mb-3">
        <div class="col d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Name" [includeMargin]="false" [(value)]="party.name" [editMode]="editMode && partyEditMode">
            <input class="input-group rounded border form-control" [(ngModel)]="party.name" required/>
          </app-form-field>
        </div>
        <div class="col d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Type of person" [includeMargin]="false" [(value)]="party.typeOfPerson" [editMode]="editMode && partyEditMode"
                          [formatter]="portVisitUtils.enumFormatter">
            <app-select [(ngModel)]="party.typeOfPerson" [options]="utils.typeOfPartyPersons"
                        [formatter]="portVisitUtils.enumFormatter"></app-select>
          </app-form-field>
        </div>
        <div class="col d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Country" [includeMargin]="false" [(value)]="party.address.country" [editMode]="editMode && partyEditMode">
            <app-search required [(ngModel)]="party.address.country" [searchFunction]="portVisitUtils.findCountries"
                        [resultFormatter]="portVisitUtils.countryFormatter" [inputFormatter]="countryFormatter"
                        (ngModelChange)="party.address.country = $event.code">
            </app-search>
          </app-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="City" [includeMargin]="false" [(value)]="party.address.city" [editMode]="editMode && partyEditMode">
            <input class="input-group rounded border form-control" [(ngModel)]="party.address.city" required/>
          </app-form-field>
        </div>
        <div class="col d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Postal code" [includeMargin]="false" [(value)]="party.address.postCode" [editMode]="editMode && partyEditMode">
            <input class="input-group rounded border form-control" [(ngModel)]="party.address.postCode"/>
          </app-form-field>
        </div>
        <div class="col d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Telephone" [includeMargin]="false" [(value)]="telephone.identifier" [editMode]="editMode && partyEditMode">
            <input class="input-group rounded border form-control" [(ngModel)]="telephone.identifier"/>
          </app-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="E-mail address" [includeMargin]="false" [(value)]="email.identifier" [editMode]="editMode && partyEditMode">
            <input class="input-group rounded border form-control" [(ngModel)]="email.identifier"/>
          </app-form-field>
        </div>
        <div class="col d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Identification number" [includeMargin]="false" [(value)]="party.identificationNumber"
                          [editMode]="editMode && partyEditMode" tooltip="Fill with EORI number of the party">
            <input class="input-group rounded border form-control" [(ngModel)]="party.identificationNumber" [required]="required"/>
          </app-form-field>
        </div>
      </div>
      <div *ngIf="editMode && partyEditMode" class="row mt-3">
        <div class="col d-flex align-items-center">
          <div class="form-check form-check-inline m-0 p-0">
            <input [(ngModel)]="party['saveInAddressBook']" class="always-enabled form-check-input m-0" type="checkbox"
                   [id]="'save'+label+'ToAddressBook'">
            <label [for]="'save'+label+'ToAddressBook'">Save {{label.toLowerCase()}} to address book</label>
          </div>
        </div>
        <div class="col-auto ms-auto">
          <button type="button" class="btn btn-outline-secondary" aria-label="Cancel" (click)="cancel()">Cancel</button>
        </div>
        <div class="col-auto">
          <button type="button" class="btn btn-primary" (click)="save()">Save</button>
        </div>
      </div>
      <button *ngIf="editMode && !partyEditMode" type="button" class="btn btn-tertiary position-absolute end-0 top-0 me-2 mt-2 p-1"
              (click)="toggleEditMode()">
        <i class="fa fa-fw fa-light fa-pencil"></i>
      </button>
    </div>
  </div>
</ng-container>
<ng-template #noParty>
  <span class="d-block mb-3">N/A</span>
</ng-template>
